import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';

export default ({ data: post }) => (
    <Layout>
        <h1>{post.markdownRemark.frontmatter.title}</h1>
        <h1>{post.markdownRemark.frontmatter.date}</h1>
        <h4>{post.markdownRemark.timeToRead} {post.markdownRemark.timeToRead > 1 ? 'minutes' : 'minute'}</h4>
        <div dangerouslySetInnerHTML={{ __html: post.markdownRemark.html }} />
    </Layout>
);

// NOTE: this is a page query - automatically run when template load then result passed into template as data
// date(formatString: "dddd, Do MMMM YYYY", locale: "EN")

export const query = graphql`
    query($id: String!) {
        markdownRemark(id: {eq: $id }) {
            frontmatter {
                title
                date(formatString: "dddd, Do MMMM YYYY", locale: "GBZ")
            }
            html
            timeToRead
        }
    }
`;

